const initialState = {
  user: null,
  token: null,
  isLoggedIn: false,
  order: null,
  orders: [],
  ordersPages: 1,
  cart: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'Login':
      return {
        ...state,
        user: action.user,
        token: action.token,
        isLoggedIn: action.isLoggedIn,
      }
    case 'Logout':
      return initialState
    case 'UpdateUser':
      return {
        ...state,
        user: action.user,
      }
    case 'GetMyCart':
      return {
        ...state,
        cart: action.cart,
      }
    case 'GetOrder':
      return { ...state, order: action.order }
    case 'GetOrders':
      return {
        ...state,
        orders: action.orders.documents,
        ordersPages: action.orders.pages,
      }
    default:
      return state
  }
}
