import { withSnackbar } from 'notistack'
import React from 'react'
import { connect } from 'react-redux'

const Snackbar = (props) => {
  if (props.snackbars.length > 0) {
    props.enqueueSnackbar(props.snackbars[0].message, {
      variant: props.snackbars[0].variant,
    })
  }
  return <></>
}
const mapStateToProps = (store) => ({
  snackbars: store.utilsReducer.snackbars,
})
export default connect(mapStateToProps)(withSnackbar(Snackbar))
