import './Style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'animate.css'
import '../fonts/edenFonts/GalanoGrotesque-Regular.WOFF'

import { SnackbarProvider } from 'notistack'
import React from 'react'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import Loading from './Loading'
import Snackbar from './snackbar'

const About = Loadable({
  loader: () => import('./aboutUs/aboutUs'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Home = Loadable({
  loader: () => import('./home/home'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
// const Navbar = Loadable({
//   loader: () => import('./navbar/navbar'),
//   loading: () => (
//     <Loading
//       height={window.innerHeight}
//       width={window.innerWidth}
//       backgroundColor="rgba(255,255,255)"
//     />
//   ),
// })
const NavbarRosa = Loadable({
  loader: () => import('./navbar/navbarRosa'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})

const Innovate = Loadable({
  loader: () => import('./innovate/innovate'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Login = Loadable({
  loader: () => import('./mainFormPage/login'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Register = Loadable({
  loader: () => import('./mainFormPage/register'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const ConfirmEmail = Loadable({
  loader: () => import('./mainFormPage/confirmEmail'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Footer = Loadable({
  loader: () => import('./footer/footer'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const BookAppointment = Loadable({
  loader: () => import('./bookAppointment/bookAppointment'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Profile = Loadable({
  loader: () => import('./profile/profile'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Shop = Loadable({
  loader: () => import('./shop/shop'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Care = Loadable({
  loader: () => import('./care/care'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Knowledge = Loadable({
  loader: () => import('./knowledge/knowledge'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Solutions = Loadable({
  loader: () => import('./solutions/solutions'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Product = Loadable({
  loader: () => import('./shop/product/product'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Cart = Loadable({
  loader: () => import('./cart/cart'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Checkout = Loadable({
  loader: () => import('./checkout/checkout'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Orders = Loadable({
  loader: () => import('./orders/orders'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const Order = Loadable({
  loader: () => import('./order/order'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const ResetPassword = Loadable({
  loader: () => import('./mainFormPage/resetPassword'),
  loading: () => (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  ),
})
const App = (props) => {
  if (props.rehydrateCompleted) {
    if (!props.user) {
      props.Logout()
    }
    return (
      <SnackbarProvider maxSnack={3}>
        {/* //removed sa3ed */}
        {/* <Navbar /> */}
        <NavbarRosa />
        {props.isLoggedIn ? (
          <Switch>
            <Route path="/order/:id" component={Order} />
            <Route path="/orders" component={Orders} />
            <Route path="/solutions" component={Solutions} />
            <Route path="/care" component={Care} />
            <Route path="/knowledge" component={Knowledge} />
            <Route path="/product/:id" component={Product} />
            <Route path="/shop" component={Shop} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/cart" component={Cart} />
            <Route path="/profile" component={Profile} />
            <Route path="/bookAppointment" component={BookAppointment} />
            <Route path="/innovate" component={Innovate} />
            <Route path="/aboutus" component={About} />
            <Route path="/login" component={Profile} />
            <Route exact path="/" component={Home} />
            <Redirect from="/" to="/" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/solutions" component={Solutions} />
            <Route path="/care" component={Care} />
            <Route path="/knowledge" component={Knowledge} />
            <Route path="/resetPassword/:token" component={ResetPassword} />
            <Route path="/confirmEmail/:token" component={ConfirmEmail} />
            <Route path="/product/:id" component={Product} />
            <Route path="/shop" component={Shop} />
            <Route path="/bookAppointment" component={BookAppointment} />
            <Route path="/register" component={Register} />
            <Route path="/cart" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/innovate" component={Innovate} />
            <Route path="/aboutus" component={About} />
            <Route exact path="/" component={Home} />
            <Redirect from="/" to="/" />
          </Switch>
        )}
        <Footer />
        <Snackbar />
      </SnackbarProvider>
    )
  }
  return (
    <Loading
      height={window.innerHeight}
      width={window.innerWidth}
      backgroundColor="rgba(255,255,255)"
    />
  )
}
const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  isLoggedIn: state.userReducer.isLoggedIn,
  rehydrateCompleted: state.rehydrateReducer.rehydrateCompleted,
})
const mapDispatchToProps = (dispatch) => ({
  Logout: () => dispatch({ type: 'Logout' }),
})
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(App))
