import React from 'react'

const Loading = (props) => (
  <div
    className="Loadingholder"
    style={{
      width: props.width ? `${props.width}px` : '100%',
      height: props.height ? `${props.height}px` : '100%',
      backgroundColor: props.backgroundColor
        ? props.backgroundColor
        : 'transparent',
      position: props.position ? props.position : 'absolute',
    }}
  >
    <div className="simpleLoading">
      <ul>
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  </div>
)
export default Loading
