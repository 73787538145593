const initialState = {
  appointment: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'CreateAppointment':
      return { ...state, appointment: action.appointment }
    default:
      return state
  }
}
