import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import reduxThunk from 'redux-thunk'

import App from './Components/App'
import reducers from './Reducers'
import { SET_REHYDRATE } from './Types'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL
const composeMiddleware = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userReducer'],
}
const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(
  persistedReducer,
  composeMiddleware(applyMiddleware(reduxThunk))
)
const persistor = persistStore(store, null, () => {
  const { token } = store.getState().userReducer
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`
  }
  store.dispatch({
    type: SET_REHYDRATE,
    value: true,
  })
})
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
