import { SET_REHYDRATE } from '../Types/index'

const initialState = {
  rehydrateCompleted: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REHYDRATE:
      return {
        rehydrateCompleted: true,
      }
    default:
      return state
  }
}
