import {
  ADD_SNACKBAR,
  changeMenuBrand,
  closeMenu,
  openMenu,
} from '../Types/utils'

const initialState = {
  menuOpen: 'initial',
  menuLogoBrand: 'eden',
  snackbars: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case openMenu:
      return { ...state, menuOpen: 'showMenu' }
    case closeMenu:
      return { ...state, menuOpen: 'hideMenu' }
    case changeMenuBrand:
      return { ...state, menuLogoBrand: action.brand }
    case ADD_SNACKBAR:
      return { ...state, snackbars: [action.snackbar, ...state.snackbars] }
    default:
      return state
  }
}
