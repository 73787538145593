const initialState = {
  products: [],
  productsPages: 1,
  product: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'GetItem':
      return { ...state, product: action.product }
    case 'GetItems':
      return {
        ...state,
        products: action.products.documents,
        productsPages: action.products.pages,
      }
    default:
      return state
  }
}
