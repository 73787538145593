import { combineReducers } from 'redux'

import appointmnetReducer from './appointment'
import contactUsReducer from './contactUs'
import itemReducer from './item'
import rehydrateReducer from './rehydrate'
import userReducer from './user'
import utilsReducer from './utils'

export default combineReducers({
  utilsReducer,
  rehydrateReducer,
  userReducer,
  contactUsReducer,
  appointmnetReducer,
  itemReducer,
})
