const initialState = {
  message: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'CreateContactUs':
      return { ...state, message: action.message }
    default:
      return state
  }
}
